
  import { dispatch, subscribe, subscribeOnce } from '@blitz/events'

  export default {
    data() {
      return {
        hasStarted: false,
        playerRating: 0,
        playerMoveIdx: 0,
        playerMoves: [],
        numPuzzlesSeen: 0,
      }
    },

    mounted() {
      const data = JSON.parse(document.querySelector('#rated-mode-data').textContent)
      console.dir(data)
      this.playerRating = data.playerRating
      this.numPuzzlesSeen = data.numPuzzlesSeen

      subscribeOnce(`puzzles:next`, () => {
        this.hasStarted = true
      })

      subscribe({
        'rated_puzzle:attempted': data => {
          this.playerRating = Math.round(data.rated_puzzle_attempt.post_user_rating)
          this.numPuzzlesSeen = data.user_rating.rated_puzzle_attempts_count
        },
        'move:make': (move, options = {}) => {
          if (!options.opponent) {
            console.log(JSON.stringify(move))
            this.addPlayerMove(move.san, 'success')
          }
        },
        'move:almost': move => this.addPlayerMove(move.san, 'almost'),
        'move:fail': move => this.addPlayerMove(move.san, 'fail')
      })
    },

    methods: {
      nextPuzzle() {
        dispatch('puzzles:next')
      },
      addPlayerMove(san: string, type: 'success' | 'almost' | 'fail') {
        this.playerMoves.unshift({
          i: this.playerMoveIdx,
          moveSan: san,
          type,
        })
        this.playerMoveIdx = this.playerMoveIdx + 1
      }
    },
  }
